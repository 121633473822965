import React from 'react';
import dataProvider from '../../providers/dataProvider'
import { GET_LIST } from 'react-admin';

class BrochuresList extends React.Component {

  render() {
    const params = { pagination: { page: 1, perPage: 100 }, sort: { field: 'lastUpdate', order: 'ASC' }, filter: { include: [] } }

    dataProvider()(GET_LIST, 'staticContents', params)
      .then(({ data = [] }) => {
        if (data && data.length > 0) {
          window.location.href = `/#/staticContents/${data[0].id}`
        }
      })

    return <div />
  }
}

export default BrochuresList