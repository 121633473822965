import React from 'react';
import { addField } from 'react-admin'
import Button from '@material-ui/core/Button';
import UploadFileButton from './../Commons/UploadFileButton'
import Grid from '@material-ui/core/Grid';


class UploadAttachementButton extends React.Component {
  render() {
    const { record, source, label, resource, maxFileSize = "1" } = this.props
    const { id } = record
    const value = record[source] || ''
    const url = `${process.env.REACT_APP_API_URL}/${resource}/${id}/attachement/${source}`
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <p><b>{label.toUpperCase()}</b>
            <br />
            <small>Sono accettati solo file con dimensioni inferiori a {maxFileSize}MB</small>
          </p>
        </Grid>
        <Grid item>
          <UploadFileButton label={`Carica ${label}`} url={url} accept="application/pdf" maxFileSize={maxFileSize} />
        </Grid>
        {value && value.length > 0 &&
          <Grid item>
            <div>
              <Button variant="outlined" color="primary" component="label" onClick={() => window.open(value, 'download')}>
                {` Scarica ${label} attuale`}
              </Button>
            </div>
          </Grid>
        }
        <div className="separator"></div>
      </Grid>
    )
  }
}




export default addField(UploadAttachementButton)