const TOKEN_KEY = 'iteka-token'
const USERID_KEY = 'iteka-user-id'

export const setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY)
}

export const setUserId = (userId) => {
    localStorage.setItem(USERID_KEY, userId);
}

export const getUserId = () => {
    return localStorage.getItem(USERID_KEY)
}



export const clearStorage = () => {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USERID_KEY)
}