import React from 'react';
import { Image } from 'cloudinary-react';

export default ({ source, record = {} }) => {
  if (!record[source]) {

    return <img src='/no-image-thumb.jpg' alt="empty"/>
  }
  const { public_id, cloud } = record[source]
  return (
    <Image cloudName={cloud} publicId={public_id} height="50" width="50" crop="scale" className="gallery-img" />
  )
}

