import React from 'react';
import UploadFileButton from '../Commons/UploadFileButton';
import { Image } from 'cloudinary-react';
import DeleteButton from '../Commons/DeleteButton/CrudDeleteButtonContainer';

export default ({ record, resource, editing }) => {
  const { cover = {}, id } = record
  const { public_id, cloud } = cover

  const url = `${process.env.REACT_APP_API_URL}/${resource}/${id}/cover`

  return <div>
    <h4>Immagine</h4>
    {
      editing ?
        <div>
          <Image cloudName={cloud} publicId={public_id} width="100" crop="scale" />
          {
            cover && Object.keys(cover).length > 0 &&
            <DeleteButton content="Vuoi eliminare l'immagine?" url={url} />
          }
          <UploadFileButton label="Carica Immagine" url={url} accept="image/*" />
        </div>
        :
        <p className="create-for-upload">Crea la promo per caricare un'immagine</p>
    }
  </div>
}