import React from 'react';
import { COMPANIES } from './../../constants';

const getCompanyName = (id) => {
  
  if (!id) return ''
  const company = COMPANIES.find(o => o.id === id)
  return company ? company.name : ''
}

const CompanyTextField = ({ source, record = {} }) => <span>{getCompanyName(record[source])}</span>

CompanyTextField.defaultProps = {
  addLabel: true
}

export default CompanyTextField;
