import React from 'react';
import { SimpleForm, Toolbar } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import UploadAttachementButton from './UploadAttachementButton'
import ImageCategories from './ImageCategories';
import Grid from '@material-ui/core/Grid'

const toolbar = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['link', 'image'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'align': [] }],
  ['clean'],
];

export default ({ basePath, ...props }) => {
  return (
    <SimpleForm {...props} submitOnEnter={false} toolbar={<Toolbar className="form-toolbar" />}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <p style={{ margin: 0 }}><b>TESTO 'CHI SIAMO'</b></p>
        </Grid>
      </Grid>
      <RichTextInput label=" " source="about" toolbar={toolbar} fullWidth />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <p style={{ margin: 0 }}><b>TESTO 'CONTATTI'</b></p>
        </Grid>
      </Grid>
      <RichTextInput label=" " source="contacts" toolbar={toolbar} fullWidth />
      <UploadAttachementButton source="brochure" label="brochure" maxFileSize={50} />
      <br />
      <UploadAttachementButton source="catalog" label="novità" maxFileSize={50} />
      <br />
      <ImageCategories />

    </SimpleForm>
  )
}