/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { maxLength } from 'react-admin';
import { connect } from 'react-redux';
import _get from 'lodash.get'
import HeaderField from './HeaderField';
import { TEMPLATES } from '../../constants';

const templates = {}

TEMPLATES.forEach((el) => {
  templates[el.id] = el
})

class FormColsVariants extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templateChanged: false
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.updatedData.header_template != this.props.updatedData.header_template) {
      this.setState({ templateChanged: true })
    } else {
      this.setState({ templateChanged: false })
    }
  }

  getSource(key) {
    return `header.${key}`
  }


  render() {
    const { updatedData } = this.props
    const { header_template } = updatedData

    const getValue = (key) => {
      return templates[header_template] && _get(templates[header_template].values, key) || ""
    }

    return (
      <React.Fragment>

        <div className="box-headers">
          <h5 className="box-headers-title">Specifiche</h5>
          <div className="box-headers-cols">
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <HeaderField label="Colonna 1" source={this.getSource('features.col_1')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_1')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 2" source={this.getSource('features.col_2')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_2')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 3" source={this.getSource('features.col_3')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_3')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 4" source={this.getSource('features.col_4')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_4')} />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <HeaderField label="Colonna 5" source={this.getSource('features.col_5')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_5')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 6" source={this.getSource('features.col_6')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_6')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 7" source={this.getSource('features.col_7')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_7')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 8" source={this.getSource('features.col_8')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('features.col_8')} />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="box-headers">
          <h5 className="box-headers-title">Scheda articolo</h5>
          <div className="box-headers-cols">
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <HeaderField label="Colonna 1" source={this.getSource('variants.col_1')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_1')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 2" source={this.getSource('variants.col_2')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_2')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 3" source={this.getSource('variants.col_3')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_3')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 4" source={this.getSource('variants.col_4')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_4')} />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <HeaderField label="Colonna 5" source={this.getSource('variants.col_5')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_5')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 6" source={this.getSource('variants.col_6')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_6')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 7" source={this.getSource('variants.col_7')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_7')} />
              </Grid>
              <Grid item xs={3}>
                <HeaderField label="Colonna 8" source={this.getSource('variants.col_8')} fullWidth validate={maxLength(500)} templateChanged={this.state.templateChanged} givenValue={getValue('variants.col_8')} />
              </Grid>
            </Grid>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    updatedData: state && state.form && state.form['record-form'] && state.form['record-form'].values
  }
}
export default connect(mapStateToProps)(FormColsVariants)