import React from 'react';
import { SimpleForm, ReferenceInput, Toolbar, DateInput, required } from 'react-admin';
import getFiltersProduct from './getFiltersProduct';
import MyAutocomplete from '../Commons/MyAutocomplete';
import PromoImage from './PromoImage';

export default ({ basePath, ...props }) => {

  const optionRenderer = record => `${record.product_code} - ${record.name}`;

  return (
    <SimpleForm {...props} submitOnEnter={false} toolbar={<Toolbar className="form-toolbar" />}>
      <ReferenceInput
        label="Prodotto"
        source="product_id"
        reference="products"
        perPage={100}
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={searchText => getFiltersProduct(searchText)}
        validate={[required()]}
      >
        <MyAutocomplete optionText={optionRenderer} />
      </ReferenceInput>

      <DateInput label="Data inizio promo (opzionale)" source="start_date" />
      <DateInput label="Data fine promo (opzionale)" source="end_date" />
      
      <PromoImage resource="promos" editing={props.editing} />

    </SimpleForm>
  )
}