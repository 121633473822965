import React from 'react';
import UploadFileButton from '../Commons/UploadFileButton';
import { Image } from 'cloudinary-react';
import DeleteButton from './../Commons/DeleteButton/CrudDeleteButtonContainer';
import { TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid'

const Gallery = ({ record, resource, editing, single }) => {
  
  const { images = [], id } = record

  const baseUrl = `${process.env.REACT_APP_API_URL}/${resource}/${id}/gallery`
  const uploadUrl = `${baseUrl}/${images.length}`

  return <div>
    <h4>Immagine</h4>
    <p>Non puoi caricare immagini più pesanti di 10MB</p>
    {
      editing ?
        <div>
          <div className="gallery-img-container">
            <Grid container spacing={24}>
              {
                images.map((image, index) => {
                  const { public_id, cloud } = image
                  return <Grid item xs={12} md={12} style={{marginBottom:'30px'}} key={index}>
                    <div>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                          <Image key={index} cloudName={cloud} publicId={public_id} height="300" width="300" crop="fit" className="gallery-img" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput label="Testo alternativo" source={`images[${index}].alt`} fullWidth />
                          <DeleteButton content="Vuoi eliminare l'immagine?" url={`${baseUrl}/${index}`} />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                })
              }
            </Grid>
          </div>
          <UploadFileButton label="Carica immmagine" url={uploadUrl} accept="image/*" />
        </div>
        :
        <p className="create-for-upload">Crea il prodotto per caricare un'immagine</p>
    }
  </div>
}

export default Gallery