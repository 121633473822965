import React from 'react';
import AttachmentButton from './../Commons/AttachmentButton';
import DeleteButton from './../Commons/DeleteButton/CrudDeleteButtonContainer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { TextInput, Button } from 'react-admin';
import Forward from '@material-ui/icons/CallMade';
import Grid from '@material-ui/core/Grid';



const FormAttachment = ({ ...data }) => {
  const { source, elName, refresource, refsource, updatedData, editing } = data
  const key = source.split(`${refsource}[`)[1].split(']')[0]
  const sourceAttachements = `${refsource}[${key}].${elName}`

  // eslint-disable-next-line
  const productId = updatedData && updatedData.id || '0'
  const attachments = get(updatedData, `${refsource}[${key}].${elName}`) || []

  return (
    <div>
      <h4>Allegati</h4>
      {
        updatedData && editing === "true" ?
          <div>
            <List>
              {
                attachments.map((attachment, index) => {
                  const { url } = attachment
                  const deleteUrl = `${process.env.REACT_APP_API_URL}/${refresource}/${productId}/attachment/${key}/${index}`
                  return <ListItem key={index}>
                    <Grid container spacing={16}>
                      <Grid item md={8}>
                        <TextInput label="Nome allegato" source={`${sourceAttachements}[${index}].name`} fullWidth />
                      </Grid>
                      <Grid item md={8}>
                        <ListItemSecondaryAction>
                          <div style={{ display: 'inline-block', marginLeft: '20px' }}>
                            <Button label="Apri" color="secondary" translate={label => label} onClick={() => window.open(url, 'download')}>
                              <Forward />
                            </Button>
                          </div>
                          <div style={{ display: 'inline-block', marginLeft: '20px' }}>
                            <DeleteButton content="Vuoi eliminare l'allegato?" url={deleteUrl} />
                          </div>
                        </ListItemSecondaryAction>
                      </Grid>
                    </Grid>
                  </ListItem>
                })
              }
            </List>
            <AttachmentButton feature={key} resource={refresource} id={productId} position={attachments.length} />
          </div>
          :
          <p className="create-for-upload">Salva il prodotto per caricare gli allegati</p>
      }
      <hr />
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    updatedData: state && state.form && state.form['record-form'] && state.form['record-form'].values
  }
}
export default connect(mapStateToProps)(FormAttachment)