import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { SimpleShowLayout, TextField, EmailField } from 'react-admin';
import packageJson from './../../../package.json';

class InfoPage extends Component {

  render() {

    const record = {
      version: packageJson.version,
      email: process.env.REACT_APP_EMAIL_SUPPORT
    }

    return <Card>
      <CardHeader title="Informazioni" />
      <CardContent>
        <SimpleShowLayout record={record}>
          <TextField source="version" label="Versione" />
          <EmailField source="email" label="Email di supporto" />
        </SimpleShowLayout>
      </CardContent>
    </Card>
  }
}

export default InfoPage