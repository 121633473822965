/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
import React from 'react';
import { SimpleForm, TextInput, required, LongTextInput, regex, Toolbar, SelectInput, maxLength, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { COMPANIES, TEMPLATES } from './../../constants'
import { getElementsTree } from '../../helpers/category';
import FormGallery from './../Commons/FormGallery';
import FeatureElement from './FeatureElement';
import FormHeaders from '../Commons/FormHeaders';
import { change } from 'redux-form'
import { connect } from 'react-redux'
const REGEX_VALIDATION_URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

const getListStyle = () => ({
  background: 'white',
  padding: 0,
  width: '100%',
})

const LabelSection = ({ name }) => (
  <h4>{name}</h4>
)

const reorder = (list, startIndex, endIndex) => {
  if (!list) {
    return
  }
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

class ProductsForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      record: props.record
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ record: nextProps.updatedRecord })
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const data = reorder(
      this.state.record.features,
      result.source.index,
      result.destination.index
    )

    const record = Object.assign({}, this.state.record, { features: data })
    this.setState({ record })
    this.props.dispatch(change('record-form', 'features', record.features))
  }

  render() {
    const { editing } = this.props
    return (
      <SimpleForm {...this.props} record={this.state.record} submitOnEnter={false} toolbar={<Toolbar className="form-toolbar" />}>
        <TextInput label="Codice prodotto (massimo 500 caratteri)" source="product_code" fullWidth validate={[required(), maxLength(500)]} />
        <TextInput label="Nome (massimo 500 caratteri)" source="name" fullWidth validate={[required(), maxLength(500)]} />
        <SelectInput label="Fornitore" source="company" choices={COMPANIES} fullWidth />
        <SelectInput label="Categoria" source="category" fullWidth choices={getElementsTree()} validate={required()} />
        <TextInput label="Link esterno" source="external_url" fullWidth validate={regex(REGEX_VALIDATION_URL, "Il valore inserito non è un url")} />
        <TextInput label="Video" source="video_url" fullWidth validate={regex(REGEX_VALIDATION_URL, "Il valore inserito non è un url")} />
        <LongTextInput label="Descrizione breve (massimo 200 caratteri)" source="short_description" fullWidth validate={[maxLength(200)]} rows="2" />
        <RichTextInput label="Descrizione" source="description" fullWidth />

        <FormGallery resource="products" editing={editing} />

        <LabelSection name="Specifiche" />

        <SelectInput label="Templte intestazioni" source="header_template" choices={TEMPLATES} fullWidth />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return formData.features && formData.features.length > 0 &&
              <FormHeaders />
          }}
        </FormDataConsumer>

        <DragDropContext
          onDragEnd={this.onDragEnd}
        >
          <Droppable droppableId="droppable" ignoreContainerClipping={true}>
            {
              (provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <ArrayInput source="features" label="" className="features_array">
                    <SimpleFormIterator classes={{ line: 'box-features-line', action: 'box-features-action' }}>
                      <FeatureElement editing={editing} />
                    </SimpleFormIterator>
                  </ArrayInput>
                  {provided.placeholder}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>

      </SimpleForm >
    )
  }
}

export default connect(function (state) {
  return {
    updatedRecord: state && state.form && state.form['record-form'] && state.form['record-form'].values || {}
  }
})(ProductsForm)