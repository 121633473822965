import React from 'react';
import { Edit, CardActions, ListButton } from 'react-admin';
import ProductForm from './ProductsForm';

const Actions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
)

export default ({ ...props }) => (
  <Edit {...props}
    title="Modifica prodotto"
    undoable={false}
    actions={<Actions />}
  >

    <ProductForm editing="true" redirect="list" />
  </Edit>
)