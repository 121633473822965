import React from 'react'
import { Button } from 'react-admin'
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiButton from '@material-ui/core/Button';

export default ({
  open,
  onConfirm,
  onClose,
  onOpen,
  title,
  content,
  confirm,
  cancel
}) => {

  return (
    <div>
      <Button label="ELIMINA" color="secondary" translate={label => label} onClick={onOpen}>
        <Delete />
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={onClose}>
            {cancel}
          </MuiButton>
          <MuiButton
            onClick={onConfirm}
            color="primary"
            className="ra-confirm"
            autoFocus>
            {confirm}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}