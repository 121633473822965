import { CATEGORIES } from './../constants';
export const getCategoryName = (id) => {
  if (!id) return ''
  const category = CATEGORIES.find(c => c.id === id)
  return category ? category.name : ''
}

export const getCategoryTree = (id) => {
  if (!id) return ''
  const category = CATEGORIES.find(c => c.id === id)
  return category.ancestors.map((ancestor) => {
    return getCategoryName(ancestor)
  }).join(' / ')
}

export const getElementsTree = () => {
  return CATEGORIES.map(el => {
    const tree = getCategoryTree(el.id)
    return { name: tree.length > 0 ? `${tree} / ${el.name}` : el.name, id: el.id }
  })
}

export const getChildren = (id) => {
  if (!id) return []
  return CATEGORIES.filter(cat => {
    return cat.ancestors.includes(id)
  }).map((cat) => {
    return cat.id
  })
  
}