import React, { Component } from 'react'
import { Button } from 'react-admin'
import Icon from '@material-ui/icons/FileUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getToken } from './../../helpers/storage';

class AttachmentButton extends Component {

  state = {
    open: false,
    loading: false,
    error: false,
    progress: {
      loaded: 0,
      total: 0
    },
    filename: '',
    file: undefined
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  onConfirm = () => {

    const { filename, file = {} } = this.state
    const { id, resource, position, acceptedFile, maxFileSizeMB, feature } = this.props
    const { size, name } = file
    const extension = name.split('.').pop().toLowerCase()

    if (size > maxFileSizeMB * 1000000) {
      this.setState({ error: 'Le dimensioni del file superano il limite consentito.' })
      return
    }

    if (acceptedFile.indexOf('.' + extension) < 0) {
      this.setState({ error: 'Il tipo di file non è consentito.' })
      return
    }

    const url = `${process.env.REACT_APP_API_URL}/${resource}/${id}/attachment/${feature}/${position}?name=${filename}`
    const formData = new FormData();

    formData.append('file', file)

    this.setState({ loading: true, error: false })

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getToken()
      },
      onUploadProgress: (progress) => this.setState({ progress })
    };

    axios.put(url, formData, config)
      .then(response => {
        window.location.reload()
      })
      .catch(error => {
        console.log('error', error)
        this.setState({ error: 'Errore nel caricamento allegato. Riprova', loading: false })
      })
  }

  render() {

    const { title, confirm, cancel, content, maxFileSizeMB, acceptedFile } = this.props

    const { open, file, filename, loading, error, progress = {} } = this.state
    const { loaded, total } = progress

    return (
      <div>
        <Button label="CARICA ALLEGATI" translate={label => label} onClick={this.handleOpen}>
          <Icon />
        </Button>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title">

          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

          <DialogContent>
            <DialogContentText>{content}</DialogContentText>
            <p className="limit-upload">
              Dimensione massima: <b>{maxFileSizeMB}MB</b>
              <br />
              File supportati: <b>{acceptedFile.join(' ')}</b>
            </p>

            <TextField
              required
              id="filename"
              label="Nome del file"
              margin="dense"
              fullWidth
              onChange={ev => this.setState({ filename: ev.target.value })} />

            <input
              accept={acceptedFile.toString()}
              type="file"
              style={{ marginTop: '20px' }}
              onChange={ev => this.setState({ file: ev.target.files[0] })} />

            {
              loading &&
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <LinearProgress variant={`${loaded === total ? 'indeterminate' : 'determinate'}`} value={loaded * 100 / total} />
              </div>
            }
            {
              error &&
              <div style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>
                {error}
              </div>
            }
          </DialogContent>

          {
            !loading &&
            <DialogActions>
              <MuiButton onClick={this.handleClose}>
                {cancel}
              </MuiButton>
              <MuiButton
                disabled={!file || !filename}
                onClick={(this.onConfirm)}
                color="primary"
                className="ra-confirm"
                autoFocus>
                {confirm}
              </MuiButton>
            </DialogActions>
          }

        </Dialog>
      </div>
    )
  }
}

AttachmentButton.propTypes = {
  title: PropTypes.string,
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  resource: PropTypes.string,
  content: PropTypes.string,
  id: PropTypes.string,
  position: PropTypes.number,
  acceptedFile: PropTypes.arrayOf(PropTypes.string),
  maxFileSizeMB: PropTypes.number
}

AttachmentButton.defaultProps = {
  acceptedFile: ['.pdf', '.dwg', '.cad','.step','.stp', '.zip'],
  maxFileSizeMB: 50,
  position: 0,
  title: 'ALLEGATO',
  confirm: 'CARICA',
  cancel: 'Annulla',
  content: `Inserisci il nome del file e seleziona un file da caricare.`
}
export default AttachmentButton