import React from 'react';
import { Edit, CardActions, ListButton } from 'react-admin';
import PromoForm from './PromoForm';

const Actions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
)

export default ({ ...props }) => (
  <Edit {...props}
    title="Modifica promo"
    undoable={false}
    actions={<Actions />}
  >

    <PromoForm editing="true" redirect="list" />
  </Edit>
)