import React from 'react';
import { List, TextField, Datagrid, EditButton, Filter, TextInput, SelectInput } from 'react-admin';
import DeleteButton from './../Commons/DeleteButton/CrudDeleteButtonContainer';
import CategoryTextField from './../Commons/CategoryTextField';
import CompanyTextField from './../Commons/CompanyTextField';
import ImageRoundField from './../Commons/ImageRoundField';
import { COMPANIES } from './../../constants';
import { getElementsTree } from '../../helpers/category';

const Filters = ({ ...props }) => (
  <Filter {...props}>
    <SelectInput label="Fornitore" source="company" choices={COMPANIES} alwaysOn />
    <SelectInput label="Categoria" source="category" choices={getElementsTree()} alwaysOn />
    <TextInput label="Ricerca per codice" source="product_code" alwaysOn />
    <TextInput label="Ricerca per nome" source="name" alwaysOn />
  </Filter>
)

export default ({ ...props }) => {

  return <List {...props}
    bulkActions={false}
    title="Prodotti"
    filters={<Filters />}
    sort={{ field: 'product_code', order: 'ASC' }}
    perPage={10}
  >

    <Datagrid>
      
      <ImageRoundField source="images" title="" />
      <TextField source="name" label="Nome" />
      <TextField source="product_code" label="Codice" />
      <CategoryTextField source="category" label="Categoria" />
      <CompanyTextField source="company" label="Fornitore" />
      <EditButton />
      <DeleteButton content="Sei sicuro di voler eliminare questo prodotto?" />
    </Datagrid>

  </List>
}