import React from 'react'
import { addField } from 'react-admin'
import UploadFileButton from '../Commons/UploadFileButton';
import { Image } from 'cloudinary-react';
import _get from 'lodash.get'
import Grid from '@material-ui/core/Grid'

const CATEGORIES = {
  'sistemi-di-presa': 'Sistemi di presa',
  'unita-rotanti': 'Unità rotanti',
  'periferiche-robot': 'Periferiche robot',
  'moduli-index': 'Moduli index',
  'moduli-lineari': ' Moduli lineari',
  'accessori': 'Accessori',
  'motori-radiali-pneumatici': 'Motori radiali pneumatici',
  'parti-elettriche-automotive': 'Parti elettriche automotive',
  'tecnica-e-sicurezza': 'Tecnica e sicurezza',
  'pubblicazioni': 'Pubblicazioni',
}

const imageCategories = ({ record }) => {
  const { id } = record
  return (
    <Grid container>
      <Grid item xs={12}>
        <p><b>IMMAGINI CATEGORIE</b></p>
      </Grid>
      {Object.keys(CATEGORIES).map((category,key) => {
        const uploadUrl = `${process.env.REACT_APP_API_URL}/staticContents/${id}/image/image_categories.${category}`
        const { cloud = '', public_id = '' } = _get(record, `image_categories.${category}`) || {}
        return (
          <Grid item xs={3} md={3} lg={3} style={{ marginBottom: '20px' }} key={key}>
            <p>{CATEGORIES[category]}</p>
            <Image cloudName={cloud} publicId={public_id} height="150" width="150" crop="scale" className="gallery-img" />
            <UploadFileButton label={`Carica immmagine`} url={uploadUrl} accept="image/*" />
          </Grid>
        )
      }
      )}
    </Grid>
  )
}

export default addField(imageCategories)