import { AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, } from 'react-admin';
import axios from 'axios';
import { getToken, setToken, clearStorage, setUserId, getUserId } from './../helpers/storage';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/login`, params)
      .then(({ data: { id, userId } = {} }) => {
        setToken(id)
        setUserId(userId)
      })
  }

  if (type === AUTH_LOGOUT) {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/logout`, {}, { headers: { Authorization: getToken() } })
      .then(() => {
        clearStorage()
        return Promise.resolve()
      })
      .catch(() => {
        clearStorage()
        return Promise.resolve()
      })
  }

  if (type === AUTH_ERROR) {
    const { response = {} } = params
    const { status } = response
    if (status === 401 || params.status === 401) {
      clearStorage()
      return Promise.reject()
    }
    return Promise.resolve()
  }

  if (type === AUTH_CHECK) {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/${getUserId()}`, { headers: { Authorization: getToken() } })
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve()
  }

  return Promise.reject('Unknown method');
};