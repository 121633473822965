import React from 'react';
import PropTypes from 'prop-types';
import { addField, TextInput } from 'react-admin'

const HeaderField = (props) => {
  const { input, givenValue, templateChanged, ...otherProps } = props

  if (templateChanged) {
    input.onChange(givenValue)
  }
  return <TextInput {...otherProps} />
}

HeaderField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  templateChanged: PropTypes.bool,
  givenValue: PropTypes.string
}

export default addField(HeaderField)