import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  main: {
    background: '#313130',
  }
})

export default withStyles(styles)(Login);
