
export const CATEGORIES = [
  {
    "id": "1000",
    "name": "Sistemi di presa",
    "ancestors": []
  },
  {
    "id": "1100",
    "name": "Assemblaggio",
    "ancestors": ["1000"]
  },
  {
    "id": "1110",
    "name": "Pinze parallele",
    "ancestors": ["1000", "1100"]
  },
  {
    "id": "1111",
    "name": "2 griffe",
    "ancestors": ["1000", "1100", "1110"]
  },
  {
    "id": "1112",
    "name": "3 griffe",
    "ancestors": ["1000", "1100", "1110"]
  },
  {
    "id": "1113",
    "name": "+ griffe",
    "ancestors": ["1000", "1100", "1110"]
  },
  {
    "id": "1120",
    "name": "Pinze angolari",
    "ancestors": ["1000", "1100"]
  },
  {
    "id": "1121",
    "name": "2 griffe",
    "ancestors": ["1000", "1100", "1120"]
  },
  {
    "id": "1122",
    "name": "3 griffe",
    "ancestors": ["1000", "1100", "1120"]
  },
  {
    "id": "1123",
    "name": "+ griffe",
    "ancestors": ["1000", "1100", "1120"]
  },
  {
    "id": "1130",
    "name": "Pinze speciali",
    "ancestors": ["1000", "1100"]
  },
  {
    "id": "1131",
    "name": "2 griffe",
    "ancestors": ["1000", "1100", "1130"]
  },
  {
    "id": "1132",
    "name": "3 griffe",
    "ancestors": ["1000", "1100", "1130"]
  },
  {
    "id": "1133",
    "name": "+ griffe",
    "ancestors": ["1000", "1100", "1130"]
  },
  {
    "id": "1200",
    "name": "Manipolazione",
    "ancestors": ["1000"]
  },
  {
    "id": "1210",
    "name": "Pinze parallele",
    "ancestors": ["1000", "1200"]
  },
  {
    "id": "1211",
    "name": "2 griffe",
    "ancestors": ["1000", "1200", "1210"]
  },
  {
    "id": "1212",
    "name": "3 griffe",
    "ancestors": ["1000", "1200", "1210"]
  },
  {
    "id": "1213",
    "name": "+ griffe",
    "ancestors": ["1000", "1200", "1210"]
  },
  {
    "id": "1220",
    "name": "Pinze angolari",
    "ancestors": ["1000", "1200"]
  },
  {
    "id": "1221",
    "name": "2 griffe",
    "ancestors": ["1000", "1200", "1220"]
  },
  {
    "id": "1222",
    "name": "3 griffe",
    "ancestors": ["1000", "1200", "1220"]
  },
  {
    "id": "1223",
    "name": "+ griffe",
    "ancestors": ["1000", "1200", "1220"]
  },
  {
    "id": "1230",
    "name": "Pinze speciali",
    "ancestors": ["1000", "1200"]
  },
  {
    "id": "1231",
    "name": "2 griffe",
    "ancestors": ["1000", "1200", "1230"]
  },
  {
    "id": "1232",
    "name": "3 griffe",
    "ancestors": ["1000", "1200", "1230"]
  },
  {
    "id": "1233",
    "name": "+ griffe",
    "ancestors": ["1000", "1200", "1230"]
  },
  {
    "id": "2000",
    "name": "Unità rotanti",
    "ancestors": []
  },
  {
    "id": "3000",
    "name": "Periferiche robot",
    "ancestors": []
  },
  {
    "id": "3100",
    "name": "Cambio utensili",
    "ancestors": ["3000"]
  },
  {
    "id": "3110",
    "name": "Automatici",
    "ancestors": ["3000", "3100"]
  },
  {
    "id": "3120",
    "name": "Manuali",
    "ancestors": ["3000", "3100"]
  },
  {
    "id": "3200",
    "name": "Compensatori",
    "ancestors": ["3000"]
  },
  {
    "id": "3300",
    "name": "Anticollisione",
    "ancestors": ["3000"]
  },
  {
    "id": "4000",
    "name": "Moduli index",
    "ancestors": []
  },
  {
    "id": "5000",
    "name": "Moduli lineari",
    "ancestors": []
  },
  {
    "id": "5100",
    "name": "Assi meccanici",
    "ancestors": ["5000"]
  },
  {
    "id": "5200",
    "name": "Cilindri senza stelo",
    "ancestors": ["5000"]
  },
  {
    "id": "5300",
    "name": "Guide lineari",
    "ancestors": ["5000"]
  },
  {
    "id": "6000",
    "name": "Accessori",
    "ancestors": []
  },
  {
    "id": "6100",
    "name": "Connettori elettrici",
    "ancestors": ["6000"]
  },
  {
    "id": "6200",
    "name": "Sensori",
    "ancestors": ["6000"]
  },
  {
    "id": "6300",
    "name": "Supporti vari",
    "ancestors": ["6000"]
  },
  {
    "id": "7000",
    "name": "Motori radiali pneumatici",
    "ancestors": []
  },
  {
    "id": "8000",
    "name": "Parti elettriche automotive",
    "ancestors": []
  },
  {
    "id": "9000",
    "name": "Tecnica e Sicurezza",
    "ancestors": []
  },
  {
    "id": "10000",
    "name": "Pubblicazioni",
    "ancestors": []
  },
]

export const COMPANIES = [
  {
    "id": "1",
    "name": "IPR",
  },
  {
    "id": "2",
    "name": "GRIP",
  },
  {
    "id": "3",
    "name": "NEWERA",
  },
  {
    "id": "4",
    "name": "MEDAN",
  },
  {
    "id": "5",
    "name": "PTM",
  },
  {
    "id": "6",
    "name": "HSB",
  },
  {
    "id": "7",
    "name": "ERO",
  }
]

export const TEMPLATES = [
  {
    "id": "1",
    "name": "Sistemi di presa",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Corsa  mm",
        "col_3": "Forza  N",
        "col_4": "Massa  kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": "",
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Corsa  mm",
        "col_3": "Forza <> N",
        "col_4": "Forza >< N",
        "col_5": "Carico Max kg",
        "col_6": "Massa  kg",
        "col_7": "Variante",
        "col_8": "",
      }
    }
  },
  {
    "id": "2",
    "name": "Unità rotanti",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Coppia nom. Nm",
        "col_3": "Angolo  °",
        "col_4": "Massa  kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": "",
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Coppia nom. Nm",
        "col_3": "Angolo ° ",
        "col_4": "Massa kg",
        "col_5": "Forza blocc. N",
        "col_6": "Diametro mm",
        "col_7": "Opzione",
        "col_8": "",
      }
    }
  },
  {
    "id": "3",
    "name": "Periferiche per robot / Cambio utensili",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Forza blocc.  N",
        "col_3": "Massa  kg",
        "col_4": "Carico Max  kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Forza blocc. N",
        "col_3": "Massa kg",
        "col_4": "Carico Max kg",
        "col_5": "Mx/y/z",
        "col_6": "Variante",
        "col_7": "Connessione elettrica",
        "col_8": "",
      }
    }
  },
  {
    "id": "4",
    "name": "Periferiche per robot / Compensatori",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Forza blocc.  N",
        "col_3": "Massa  kg",
        "col_4": "Compressione  N",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Forza blocc. N",
        "col_3": "Corsa mm",
        "col_4": "Massa kg",
        "col_5": "Carico max kg",
        "col_6": "Mx/y/z",
        "col_7": "",
        "col_8": "",
      }
    }
  },
  {
    "id": "5",
    "name": "Periferiche per robot / Anticollisione",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Forza blocc.  N",
        "col_3": "Massa  kg",
        "col_4": "Carico Max  kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Forza blocc. N",
        "col_3": "Massa kg",
        "col_4": "carico Max kg",
        "col_5": "Variante",
        "col_6": "",
        "col_7": "",
        "col_8": "",
      }
    }
  },
  {
    "id": "6",
    "name": "Moduli index",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Corsa  mm",
        "col_3": "Forza  N",
        "col_4": "Massa  kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Corsa  mm",
        "col_3": "Forza <> N",
        "col_4": "Forza >< N",
        "col_5": "Carico Max kg",
        "col_6": "Massa  kg",
        "col_7": "Variante",
        "col_8": "",
      }
    }
  },
  {
    "id": "8",
    "name": "Moduli lineari",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Corsa  mm",
        "col_3": "Forza  N",
        "col_4": "Massa  kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Corsa  mm",
        "col_3": "Mom. Assiale Nm",
        "col_4": "Mom. Radiale Nm",
        "col_5": "Mom. Centrale Nm",
        "col_6": "Massa  kg",
        "col_7": "",
        "col_8": "",
      }
    }
  },
  {
    "id": "9",
    "name": "Accessori / Connettori elettrici",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Poli",
        "col_3": "tensione A",
        "col_4": "voltaggio V",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Poli",
        "col_3": "Tensione A",
        "col_4": "Voltaggio V",
        "col_5": "Massa  kg",
        "col_6": "Cavo m",
        "col_7": "",
        "col_8": "",
      }
    }
  },
  {
    "id": "10",
    "name": "Accessori / Sensori / Supporti vari",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Funzione",
        "col_3": "",
        "col_4": "",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Funzione",
        "col_3": "Opzione",
        "col_4": "Massa kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": "",
      }
    }
  },
  {
    "id": "11",
    "name": "Motori pneumatici radiali",
    "values": {
      "features": {
        "col_1": "Articolo",
        "col_2": "Coppia nom. Nm",
        "col_3": "Giri  rpm",
        "col_4": "Massa  kg",
        "col_5": "",
        "col_6": "",
        "col_7": "",
        "col_8": ""
      },
      "variants": {
        "col_1": "Articolo",
        "col_2": "Coppia Nm",
        "col_3": "Giri rpm",
        "col_4": "Materiale",
        "col_5": "Massa  kg",
        "col_6": "",
        "col_7": "",
        "col_8": "",
      }
    }
  },
]