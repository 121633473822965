import React from 'react';
import { List, Datagrid, EditButton, DateField } from 'react-admin';
import DeleteButton from './../Commons/DeleteButton/CrudDeleteButtonContainer';
import ImageField from './ImageField';

const include = {
  relation: "product",
  scope: {
    fields: ['name', 'slug']
  }
}
const Product = ({ record }) => {
  const { product = {} } = record
  const { name = '' } = product || {}
  return <div>{name || '-'}</div>
}

export default ({ ...props }) => {

  return (
    <List {...props}
      bulkActions={false}
      title="Promo"
      filters={null}
      filter={{ include }}
      perPage={10}
    >

      <Datagrid>
        <Product label="Prodotto" />
        <ImageField source="image" />
        <DateField label="Data inizio promo (opzionale)" source="start_date" />
        <DateField label="Data fine promo (opzionale)" source="end_date" />
        <EditButton />
        <DeleteButton content="Sei sicuro di voler eliminare questa promo?" />
      </Datagrid>

    </List>
  )
}