import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextInput, maxLength } from 'react-admin';
import { connect } from 'react-redux';

const FormColsVariants = ({ source, updatedData }) => {
  // const key = /[0-9]/gm.exec(source)

  const getSource = (key) => {
    return source.replace('undefined', key)
  }

  return (
    <React.Fragment>
      <Grid container spacing={24}>
        <Grid item xs={3}>
          <TextInput label="Colonna 1" source={getSource('col_1')} fullWidth validate={maxLength(500)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Colonna 2" source={getSource('col_2')} fullWidth validate={maxLength(500)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Colonna 3" source={getSource('col_3')} fullWidth validate={maxLength(500)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Colonna 4" source={getSource('col_4')} fullWidth validate={maxLength(500)} />
        </Grid>
      </Grid>
      <Grid container spacing={24}>
        <Grid item xs={3}>
          <TextInput label="Colonna 5" source={getSource('col_5')} fullWidth validate={maxLength(500)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Colonna 6" source={getSource('col_6')} fullWidth validate={maxLength(500)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Colonna 7" source={getSource('col_7')} fullWidth validate={maxLength(500)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Colonna 8" source={getSource('col_8')} fullWidth validate={maxLength(500)} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    updatedData: state && state.form && state.form['record-form'] && state.form['record-form'].values
  }
}
export default connect(mapStateToProps)(FormColsVariants)