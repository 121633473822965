import React from 'react';
import { Edit } from 'react-admin';
import StaticContentsForm from './StaticContentsForm';


export default ({ ...props }) => (
  <Edit {...props}
    title="Contenuti statici"
    undoable={false}
    actions={false}
  >

    <StaticContentsForm editing="true" redirect="edit" />
  </Edit>
)