import React from 'react'
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import FormAttachments from './../Commons/FormAttachments';
import FormColsCaracteristics from '../Commons/FormColsCaracteristics';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MuiButton from '@material-ui/core/Button';
import PagesIcon from '@material-ui/icons/Pages';
import AttachementIcon from '@material-ui/icons/Attachment';
import { Draggable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: '0 8px',
  margin: `0`,
  background: isDragging ? '#E13B3B' : 'white',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  ...draggableStyle,
});

export default class FeatureElement extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      attachementsOpen: false
    }
    this.getSource = this.getSource.bind(this)
  }

  getSource(key) {
    const { source } = this.props
    return source.replace('undefined', key)
  }


  render() {
    const { editing, index, ...props } = this.props
    const key = parseInt(/\[((-?)\d*)\]/gm.exec(props.source)[1])

    return (
      <Draggable
        key={key}
        draggableId={key+1}
        index={key}
        isCombineEnabled
      >
        {
          (provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <FormColsCaracteristics {...props} />

              <Dialog
                fullWidth
                maxWidth="md"
                open={this.state.open}
                onClose={() => { this.setState({ open: false }) }}
                aria-label="Specifiche tecniche"
              >
                <DialogTitle>Scheda articolo</DialogTitle>
                <DialogContent>
                  <ArrayInput source={this.getSource('variants')} label={" "} className="remove_underline">
                    <SimpleFormIterator >
                      <FormColsCaracteristics />
                    </SimpleFormIterator>
                  </ArrayInput>

                  <MuiButton
                    onClick={() => this.setState({ open: false })}
                    color="primary"
                    className="ra-confirm"
                    autoFocus>
                    Chiudi
              </MuiButton>

                </DialogContent>
              </Dialog>

              <Dialog
                fullWidth
                maxWidth="md"
                open={this.state.attachementsOpen}
                onClose={() => { this.setState({ attachementsOpen: false }) }}
                aria-label="Allegati"
              >
                <DialogTitle>Allegati</DialogTitle>
                <DialogContent>
                  <FormAttachments {...props} elName="attachments" refresource="products" refsource="features" editing={editing} />
                  <MuiButton
                    onClick={() => this.setState({ attachementsOpen: false })}
                    color="primary"
                    className="ra-confirm"
                    autoFocus>
                    Chiudi
              </MuiButton>

                </DialogContent>
              </Dialog>

              <MuiButton
                onClick={() => this.setState({ open: true })}
                color="primary"
                className="ra-confirm m-10"
                autoFocus
              >
                <PagesIcon style={{ marginRight: '10px' }} /> Scheda articolo
              </MuiButton>

              <MuiButton
                onClick={() => this.setState({ attachementsOpen: true })}
                color="primary"
                className="ra-confirm m-10"
                autoFocus
              >
                <AttachementIcon style={{ marginRight: '10px' }} /> Allegati
              </MuiButton>
            </div>
          )
        }
      </Draggable>
    )
  }
}