import axios from 'axios';
import paramsSerializer from './paramsSerializer'
import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, GET_MANY } from 'react-admin';
import { getToken } from './../helpers/storage'
import { getChildren } from './../helpers/category'
import { formatErrorResponse } from './../helpers/errorReponse'

export default () => {

  const apiUrl = process.env.REACT_APP_API_URL

  const convertDataRequestToHTTP = (type, resource, params) => {
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { include = [] } = params.filter

        const query = {
          filter: {
            order: `${field} ${order}`,
            skip: (page - 1) * perPage,
            limit: perPage,
            where: {},
            include
          }
        };

        const filters = params.filter
        delete filters.include

        for (const f in filters) {
          if (filters.hasOwnProperty(f)) {
            if (filters[f] === undefined) {
              // do nothing
            }
            else if (filters[f] === null) {
              query.filter.where[f] = null
            }
            else {
              const key = f.replace(/%%/g, '.');
              query.filter.where[key] = isLike(key) ? { like: '.*' + filters[f] + '.*', options: 'i' } : filters[f]
            }

            if (f === 'category') {
              const children = getChildren(filters[f])
              children.push(filters[f])
              query.filter.where[f] = { inq: children }
            }
          }
        }

        options.url = `${apiUrl}/${resource}`;
        options.method = 'GET';
        options.params = query
        break;
      }
      case GET_ONE:
        options.url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'GET';
        break;
      case UPDATE:
        options.url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.data = params.data;
        break;
      case CREATE:
        options.url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.data = params.data;
        break;
      case DELETE:
        options.url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      case GET_MANY: {
        const query = {
          filter: {
            where: { id: { inq: params.ids } }
          }
        };
        options.method = 'GET';
        options.url = `${apiUrl}/${resource}`;
        options.params = query
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return options;
  };

  const convertHTTPResponse = (response, type, resource, params) => {

    const { headers, data } = response;

    switch (type) {
      case GET_LIST:
        if (!headers['x-total-count']) {
          throw new Error(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data,
          total: parseInt(headers['x-total-count'].split('/').pop(), 10),
        };
      case CREATE:
        return { data: { ...params.data, id: data.id } };
      default:
        return { data };
    }
  };

  return (type, resource, params) => {
    const options = convertDataRequestToHTTP(type, resource, params);
    options.paramsSerializer = paramsSerializer

    const token = getToken()
    if (token) {
      options.headers = options.headers || {}
      options.headers['Authorization'] = token
    }

    return axios.request(options)
      .then(response => convertHTTPResponse(response, type, resource, params)).catch((e) => {
          return Promise.reject(formatErrorResponse(e))
      })
  };
};

function isLike(key) {
  const FILTERS_FOR_LIKE = ['name','product_code']
  for (const filter of FILTERS_FOR_LIKE) {
    if (key.indexOf(filter) > -1) return true
  }
  return false
}
