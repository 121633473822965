import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';

const Menu = ({ resources, onMenuClick, logout }) => (
  <div>
    {
      resources.map(resource => {
        const { name, options = {}, icon } = resource
        const { label } = options

        return (
          <MenuItemLink
            key={name}
            to={`/${name}`}
            primaryText={label || name}
            onClick={onMenuClick}
            leftIcon={icon ? <resource.icon /> : null}
          />
        )
      })
    }

    <MenuItemLink
      to="/info"
      primaryText="Info"
      onClick={onMenuClick}
      leftIcon={<InfoIcon />}
    />
    
  </div>
);

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(Menu))