import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'react-admin';
import axios from 'axios'
import { connect } from 'react-redux'
import { showNotification, FETCH_START, FETCH_END } from 'react-admin'
import { getToken } from './../../helpers/storage';
import Button from '@material-ui/core/Button';

const UploadFileButton = ({ url, label, accept, showNotification, showLoading, hideLoading, maxFileSize }) => {

  const onChange = (e) => {
    const byteMaxFileSize = parseInt(Math.floor(maxFileSize * (1024 * 1024)))
    const file = e.target.files[0]
    if (!file) {
      return
    }
    const { size } = file
    if (size >= byteMaxFileSize) {
      showNotification('ra.notification.tooBigFileSize', 'warning')
      return
    }
    const formData = new FormData();

    formData.append('file', file)

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getToken()
      }
    }

    showLoading()

    axios.put(url, formData, config)
      .then(() => {
        showNotification('ra.notification.updated', 'success')
        window.location.reload();
      })
      .catch(error => {
        hideLoading()
        if (error.response) {
          const {
            response: {
              data: {
                error: { message = 'ra.notification.http_error' } = {}
              } = {}
            } = {}
          } = error
          showNotification(message, 'warning')
        } else {
          showNotification('ra.notification.http_error', 'warning')
        }

      });
  }

  return <div>
    <input
      id={`file-${url}`}
      type="file"
      accept={accept}
      onChange={onChange}
      style={{
        width: 0,
        height: 0,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 1,
      }}
    />
    <Button variant="outlined" color="primary" component="label" htmlFor={`file-${url}`}>
      {`${label || 'Upload'}`}
    </Button>
  </div>
}

UploadFileButton.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.string
}

const showLoading = () => ({ type: FETCH_START })
const hideLoading = () => ({ type: FETCH_END })

export default connect(null, { showNotification, showLoading, hideLoading })(UploadFileButton)
