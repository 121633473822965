import React, { Component } from 'react';
import './App.css';
import { Admin, Resource } from 'react-admin';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import italian from './translations/italian';
import deleteSaga from './sagas/deleteSaga';
import customRoutes from './routes';
import dataProvider from './providers/dataProvider';
import Menu from './../src/components/Commons/Menu';
import authProvider from './providers/authProvider';
import LoginPage from './components/Commons/LoginPage';

import ProductsList from './../src/components/Products/ProductsList';
import ProductsCreate from './../src/components/Products/ProductsCreate';
import ProductsEdit from './../src/components/Products/ProductsEdit';
import ProductsIcon from '@material-ui/icons/Toys';

import StaticContentsIcon from '@material-ui/icons/Book';
import StaticContentsEdit from './components/StaticContents/StaticContentsEdit';
import StaticContentsList from './components/StaticContents/StaticContentsList';

import PromoList from './components/Promo/PromoList';
import PromoIcon from '@material-ui/icons/Favorite';
import PromoCreate from './components/Promo/PromoCreate';
import PromoEdit from './components/Promo/PromoEdit';


const theme = createMuiTheme({
  palette: {
    primary: { main: '#E13B3B' },
    secondary: { main: '#313130' }
  }
});
class App extends Component {
  render() {

    return (
      <Admin
        dataProvider={dataProvider()}
        i18nProvider={() => italian}
        loginPage={LoginPage}
        authProvider={authProvider}
        customSagas={[deleteSaga]}
        customRoutes={customRoutes}
        theme={theme}
        menu={Menu}
        title="Iteka"
      >

        <Resource
          name="products"
          options={{ label: 'Prodotti' }}
          list={ProductsList}
          edit={ProductsEdit}
          icon={ProductsIcon}
          create={ProductsCreate}
        />
        <Resource
          name="staticContents"
          options={{ label: 'Contenuti Statici' }}
          list={StaticContentsList}
          edit={StaticContentsEdit}
          icon={StaticContentsIcon}
        // create={ProductsCreate}
        />
        <Resource
          name="promos"
          options={{ label: 'Prodotti in promozione' }}
          list={PromoList}
          edit={PromoEdit}
          icon={PromoIcon}
          create={PromoCreate}
        />


      </Admin>
    )
  }
}

export default App;
