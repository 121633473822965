import React, { Component } from 'react'
import { connect } from 'react-redux'
import CrudDeleteButtonPresentation from './CrudDeleteButtonPresentation'
import { crudDelete, showNotification, FETCH_START, FETCH_END, CRUD_DELETE_SUCCESS } from 'react-admin';
import axios from 'axios';
import { getToken } from './../../../helpers/storage';

class CrudDeleteButton extends Component {

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {

    const {
      crudDelete,
      showNotification,
      showLoading,
      hideLoading,
      crudDeleteSuccess,
      title = 'Elimina',
      content = 'Sei sicuro di voler eliminare l\'elemento selezionato?',
      confirm = 'Sì',
      cancel = 'Annulla',
      resource,
      record,
      basePath,
      redirect,
      url
    } = this.props

    return (
      <CrudDeleteButtonPresentation open={this.state.open}
        onConfirm={() => {
          this.handleClose()

          if (resource && Object.keys(record).length > 0) {
            crudDelete(resource, record.id, record, basePath, redirect)
          }
          else if (url) {
            showLoading()

            const config = {
              headers: {
                'Authorization': getToken()
              }
            }
            
            axios.delete(url, config)
              .then(() => {
                showNotification('ra.notification.updated', 'success')
                crudDeleteSuccess()
              })
              .catch(err => {
                hideLoading()
                showNotification('ra.notification.http_error', 'warning')
              })
          }
        }}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        title={title}
        content={content}
        confirm={confirm}
        cancel={cancel} />
    )
  }
}


const showLoading = () => ({ type: FETCH_START })
const hideLoading = () => ({ type: FETCH_END })
const crudDeleteSuccess = () => ({ type: CRUD_DELETE_SUCCESS })

export default connect(null, { crudDelete, showNotification, showLoading, hideLoading, crudDeleteSuccess })(CrudDeleteButton);