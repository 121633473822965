export default (text = '') => {
  const obj = {
    or: [
      { slug: { like: '.*' + text + '.*', options: 'i' } },
      { name: { like: '.*' + text + '.*', options: 'i' } },
      { product_code: { like: '.*' + text + '.*', options: 'i' } },
    ]
  }

  return obj
}
