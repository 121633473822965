
export const formatErrorResponse = (e) => {
  if (e && e.response && e.response.data && e.response.data.error) {
    const code = e.response.data.error.code
    switch (code) {
      case 11000:
        return "ra.validation.duplicate_key"
      default:
        return "ra.errors.generic_error"
    }
  }
  return e
}