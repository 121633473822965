import React from 'react';
import Tooltip from '@material-ui/core/Tooltip'
import { getCategoryName, getCategoryTree } from './../../helpers/category'


export default ({ source, record = {} }) => {
  return (
    <Tooltip title={getCategoryTree(record[source])} placement="top">
      <span>{getCategoryName(record[source])}</span>
    </Tooltip>
  )
}

